import axios from "axios";

let axiosInstance = axios.create({});

const token = localStorage.getItem("token");
axiosInstance.defaults.headers.common["Authorization"] = `bearer ${
  token ? JSON.parse(token) : null
}`;
// axiosInstance.defaults.headers["Demonint Header"] = "Demonint Header";
// axiosInstance.defaults.params.token = "JwtToken";

export default axiosInstance;
