/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useHTTP from "../../config/http/useHTTP";

import * as api from "../../config/api/api";
import Loader from "../../components/reusable/loader/loader";
import { Button, Col, Row, Table } from "antd";
import Save from "../../components/reusable/buttons/saveButton";
import DataTable from "../../components/reusable/table/dataTable";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";
import { BLOOD_GROUPS, GENDERS } from "../../utils/lists/lists";

export default function Member() {
  let [data, setData] = useState([]);
  let [districtArray, setDistrictArray] = useState([]);
  let [upazila, setUpazila] = useState([]);
  let [profession, setProfession] = useState([]);
  let [designation, setDesignation] = useState([]);
  let [bloodGroup, setBloodGroup] = useState(BLOOD_GROUPS);
  let [gender, setGender] = useState(GENDERS);
  let [formData, setFormData] = useState({});
  let [loader, setLoader] = useState(false);
  let [updateMode, setUpdateMode] = useState(false);

  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log("district state===>", districtArray);
  }, [districtArray]);

  let getData = async () => {
    setLoader(true);
    let members = await GetData({ api: api.GET_ALL_MEMBER });
    let designations = await GetData({ api: api.DESIGNATIONS });
    let professions = await GetData({ api: api.PROFESSIONS });
    let district = await GetData({ api: api.DISTICTS });
    setLoader(false);
    // console.log(district.data);
    setData(members.data);
    setProfession(professions.data);
    setDesignation(designations.data);
    setDistrictArray(district.data);
  };

  let getUpazilas = async (districtId) => {
    if (districtId !== (null && undefined)) {
      setLoader(true);
      let upazila = await GetData({
        api: `${api.UPAZILA_BY_DISTRICT_ID}?districtId=${districtId}`,
      });
      // console.log("upazila data===>", upazila.data);
      setUpazila(upazila.data);

      setLoader(false);
    }
  };

  let editHandler = async (object) => {
    console.log("district array from edit handler===>", districtArray);

    setFormData(object);
    setUpdateMode(true);

    let districtData = districtArray.find(
      (e) => e.district === object.district
    );
    await getUpazilas(districtData.district);

    window.scroll({ top: 0, behavior: "smooth" });
  };

  let deleteHandler = async (object) => {
    if (window.confirm()) {
      setLoader(true);

      let res = await DeleteData({
        api: `${api.DELETE_MEMBER}/${object.id}`,
      });
      console.log({ res });

      if (res) {
        setData((prevData) => prevData.filter((e) => e.id !== object.id));
      }

      setLoader(false);
    }
  };

  let onSubmitHandler = async (object) => {
    console.log({ object });
    setLoader(true);

    if (updateMode) {
      let res = await UpdateData({ api: api.UPDATE_MEMBER, body: object });
      if (res) {
        setData((prevData) =>
          prevData.map((e) => {
            if (e.id === object.id) {
              e = { ...object };
            }
            return e;
          })
        );
        setFormData({});
      }
    } else {
      let res = await PostData({ api: api.CREATE_MEMBER, body: object });
      if (res) {
        setData((prevData) => [...prevData, res.data]);
        setFormData({});
      }
    }

    setLoader(false);
  };

  let onCancelHandler = () => {
    setUpdateMode(false);
  };
  let onChangeObserveHandler = async (obj) => {
    console.log({ obj });

    if (obj?.key === "district" && obj?.value?.length) {
      await getUpazilas(obj?.value);
    }
  };

  return (
    <div>
      {loader && <Loader />}
      <Button onClick={() => console.log("districts===>", districtArray)}>
        Districts
      </Button>
      <Row gutter={[24, 24]}>
        <Col md={24}>
          <FormGenerator
            // customLayout
            generatorData={formGenerator({
              profession,
              designation,
              bloodGroup,
              gender,
              districtArray,
              upazila,
            })}
            formData={formData}
            formRules={formRules}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
            onChangeObserver={onChangeObserveHandler}
          />
        </Col>

        <Col md={24} className="card pa-4">
          <DataTable
            rowKey="id"
            columns={headers}
            dataSource={data}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
          />
        </Col>
      </Row>
    </div>
  );
}

let headers = [
  { title: "Id", dataIndex: "id" },
  { title: "Profession", dataIndex: "profession" },
  { title: "Address", dataIndex: "address" },
  { title: "Blood", dataIndex: "bloodGroup" },
  { title: "Designation", dataIndex: "designation" },
  { title: "District", dataIndex: "district" },
  { title: "DOB", dataIndex: "dob" },
  { title: "Email", dataIndex: "email" },
  { title: "Gender", dataIndex: "gender" },
  { title: "Institution", dataIndex: "institutionName" },
  { title: "Name", dataIndex: "name" },
  { title: "Organization", dataIndex: "organization" },
  { title: "Phone", dataIndex: "phone" },
  { title: "Image", dataIndex: "picPath", type: "image" },
  { title: "Upazila", dataIndex: "upazila" },
  { title: "created at", dataIndex: "created_at", type: "date" },
  { title: "", type: "update" },
  { title: "", type: "delete" },
];

let formGenerator = ({
  profession = [],
  designation = [],
  bloodGroup = [],
  gender = [],
  districtArray = [],
  upazila = [],
}) => [
  {
    type: "input",
    label: "Name",
    placeholder: "Insert Name",
    key: "name",
    required: true,
  },
  {
    type: "input",
    label: "Phone",
    placeholder: "Insert Phone",
    key: "phone",
    required: true,
  },
  {
    type: "input",
    label: "Address",
    placeholder: "Insert Address",
    key: "address",
    required: true,
  },
  {
    type: "input",
    label: "Email",
    placeholder: "Insert Email",
    key: "email",
    required: true,
  },
  {
    type: "input",
    label: "Institution Name",
    placeholder: "Insert Institution Name",
    key: "institutionName",
    required: true,
  },
  {
    type: "input",
    label: "Organization",
    placeholder: "Insert Organization",
    key: "organization",
    required: true,
  },
  {
    type: "date",
    label: "Date Of Birth",
    placeholder: "Insert Date Of Birth",
    key: "dob",
    required: true,
  },
  {
    type: "select",
    label: "Profesion",
    placeholder: "Select Profesion",
    key: "professionId",
    required: true,
    items: profession,
    itemText: "profession",
    itemValue: "id",
  },
  {
    type: "select",
    label: "Designation",
    placeholder: "Select Designation",
    key: "designationId",
    required: true,
    items: designation,
    itemText: "designation",
    itemValue: "id",
  },
  {
    type: "select",
    label: "Blood Group",
    placeholder: "Select Blood Group",
    key: "bloodGroup",
    required: true,
    items: bloodGroup,
    itemText: "bloodGroup",
    itemValue: "id",
  },
  {
    type: "select",
    label: "Gender",
    placeholder: "Select Gender",
    key: "gender",
    required: true,
    items: gender,
    itemText: "gender",
    itemValue: "id",
  },
  {
    type: "select",
    label: "District",
    placeholder: "Select District",
    key: "district",
    required: true,
    items: districtArray,
    itemText: "district",
    itemValue: "id",
    observeOnChange: true,
  },
  {
    type: "select",
    label: "Upazila",
    placeholder: "Select Upazila",
    key: "upazilaId",
    required: true,
    items: upazila,
    itemText: "upazila",
    itemValue: "id",
  },
];

let formRules = {
  name: {
    required: true,
  },
  phone: {
    required: true,
    type: "phone",
  },
  address: {
    required: true,
  },
  email: {
    required: true,
  },
  institutionName: {
    required: true,
  },
  organization: {
    required: true,
  },
  dob: {
    required: true,
  },
  professionId: {
    required: true,
  },
  designationId: {
    required: true,
  },
  bloodGroup: {
    required: true,
  },
  gender: {
    required: true,
  },
  district: {
    required: true,
  },
  upazilaId: {
    required: true,
  },
};
