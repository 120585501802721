/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import validator from "../../../../utils/utilityFunctions/formValidation";
import Delete from "../../buttons/deleteButton";
import Save from "../../buttons/saveButton";
import Update from "../../buttons/updateButton";
import ReactDraftTextEditor from "../../textEditor/reactDraftTextEditor";
import DatePickerComponent from "../datePicker/datePicker";
import InputComponent from "../input/input";
import SelectComponent from "../select/select";

export default function FormGenerator({
  generatorData = [],
  formData = {},
  formRules = {},
  errorData = {},
  customErrorHandling = false,
  customLayout,
  gutter = [24, 12],
  className = "",
  style = {},
  updateMode = false,
  footer,

  onSubmit = () => {},
  onCancel = () => {},
  onChangeObserver = () => {},
}) {
  let [data, setData] = useState(Object.keys(formData).length ? formData : {});
  let [formDataBluePrint, setFormDataBluePrint] = useState(
    Object.keys(data).reduce((object, key) => ({ ...object, [key]: null }), {})
  );
  let [errors, setErrors] = useState(
    Object.keys(errorData).length ? errorData : {}
  );

  useEffect(() => {
    setData(Object.keys(formData).length ? formData : {});
  }, [formData]);

  let submitHandler = () => {
    // console.log({ data });
    if (!customErrorHandling) {
      setErrors({});
      let validationErrors = validator(data, formRules);
      console.log({ validationErrors });
      if (Object.keys(validationErrors).length) {
        setErrors(validationErrors);
        return;
      }
    }
    onSubmit(data);
  };

  let onChangeObserverHandler = (object) => {
    onChangeObserver(object);
  };

  let reset = () => {
    setData(formDataBluePrint);
    setErrors({});
    onCancel();
  };

  return (
    <Row gutter={gutter} className={className} style={{ ...style }}>
      {generatorData.map((component, index) => (
        <Col
          key={component.key}
          xxl={component.xxl ? component.xxl : !customLayout ? 4 : null}
          xl={component.xl ? component.xl : !customLayout ? 4 : null}
          lg={component.lg ? component.lg : !customLayout ? 6 : null}
          md={component.md ? component.md : !customLayout ? 8 : null}
          sm={component.sm ? component.sm : !customLayout ? 12 : null}
          xs={component.xs ? component.xs : !customLayout ? 24 : null}
          span={component.span ? component.span : 24}
        >
          {component.type === "input" ? (
            <InputComponent
              size={component.size ? component.size : "large"}
              label={component.label}
              required={component.required}
              placeholder={component.placeholder}
              fieldType={component.fieldType}
              type={component.inputType}
              value={data[component.key]}
              onChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  [component.key]: event.target.value,
                }));

                component.observeOnChange &&
                  onChangeObserver({
                    key: component.key,
                    value: event.target.value,
                  });
              }}
              errorText={errors[component.key]}
            />
          ) : component.type === "select" ? (
            <SelectComponent
              size={component.size ? component.size : "large"}
              label={component.label}
              required={component.required}
              items={component.items}
              itemText={component.itemText}
              itemValue={component.itemValue}
              placeholder={component.placeholder}
              value={data[component.key]}
              onChange={(value) => {
                setData((prevState) => ({
                  ...prevState,
                  [component.key]: value,
                }));

                component.observeOnChange &&
                  onChangeObserverHandler({ key: component.key, value: value });
              }}
              errorText={errors[component.key]}
            />
          ) : component.type === "date" ? (
            <DatePickerComponent
              size={component.size ? component.size : "large"}
              label={component.label}
              required={component.required}
              placeholder={component.placeholder}
              value={data[component.key]}
              onChange={(value, stringValue) => {
                setData((prevState) => ({
                  ...prevState,
                  [component.key]: stringValue,
                }));
              }}
              errorText={errors[component.key]}
            />
          ) : component.type === "textEditor" ? (
            <ReactDraftTextEditor
              size={component.size ? component.size : "large"}
              label={component.label}
              required={component.required}
              placeholder={component.placeholder}
              value={data[component.key]}
              onChange={(value) => {
                setData((prevState) => ({
                  ...prevState,
                  [component.key]: value,
                }));
                component.observeOnChange &&
                  onChangeObserver({
                    key: component.key,
                    value: value,
                  });
              }}
              errorText={errors[component.key]}
            />
          ) : null}
        </Col>
      ))}
      {
        <Col md={24}>
          <span onClick={submitHandler} className="mr-2">
            {updateMode ? <Update /> : <Save type="primary" />}
          </span>

          <Delete size="medium" btnName="Cancel" onClick={reset} />
        </Col>
      }
      {footer && <Col md={24}> {footer}</Col>}
    </Row>
  );
}

/* --------------------------- */
/* ------demo data ----------- */
/* --------------------------- */

// let formGenerator = [
//   {
//     type: "input",
//     label: "Contact Name",
//     key: "contact",
//     md: 8,
//     required: true,
//     placeholder: "Admin",
//     observeOnChange: true
//   },

//   {
//     type: "select",
//     label: "Country",
//     key: "country",
//     md: 8,
//     required: true,
//     items: countries,
//     placeholder: "India",
//   },

//   {
//     type: "select",
//     label: "City",
//     key: "city",
//     md: 8,
//     required: true,
//     items: items,
//     itemText: "name",
//     itemValue: "id"
//     placeholder: "Chenai",
//   },

//   {
//     type: "input",
//     label: "Address Two",
//     key: "addressTwo",
//     md: 8,
//     placeholder: "Address",
//   },
// ];

// let items = [
//   {
//     name: "One",
//     id: "1",
//   },
//   {
//     name: "Two",
//     id: "2",
//   },
// ];

// let countries = [
//   "Bangladesh",
//   "Bhutan",
//   "India",
//   "Maldive",
//   "Nepal",
//   "Pakistan",
//   "Sri Lanka",
// ];

// let cities = [
//   "Dhaka",
//   "Kathmundu",
//   "Dilhi",
//   "Washington DC",
//   "London",
//   "Karachi",
//   "Mosco",
// ];

// let formData = {
//   fatherName: "",
//   guardianMobile: "",
//   motherName: "",
//   studentGender: "",
//   studentName: "",
//   studentReligion: "",
//   studentRoll: "",
// };

// let errorData = {
//   fatherName: "",
//   guardianMobile: "",
//   motherName: "",
//   studentGender: "",
//   studentName: "",
//   studentReligion: "",
//   studentRoll: "",
// };
