/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useHTTP from "../../config/http/useHTTP";

import * as api from "../../config/api/api";
import Loader from "../../components/reusable/loader/loader";
import { Col, Row, Table } from "antd";
import Save from "../../components/reusable/buttons/saveButton";
import DataTable from "../../components/reusable/table/dataTable";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";

export default function Designation() {
  let [data, setData] = useState([]);
  let [formData, setFormData] = useState({});
  let [loader, setLoader] = useState(false);
  let [updateMode, setUpdateMode] = useState(false);

  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();

  useEffect(() => {
    getData();
  }, []);

  let getData = async () => {
    console.log({ designation: api.GET_ALL_DESIGNATION });
    setLoader(true);
    let res = await GetData({ api: api.GET_ALL_DESIGNATION });
    setLoader(false);
    // console.log(res.data);
    if (res) {
      setData(res.data);
    }
  };

  let editHandler = (object) => {
    // console.log(object)
    setFormData(object);
    setUpdateMode(true);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  let deleteHandler = async (object) => {
    if (window.confirm()) {
      setLoader(true);

      let res = await DeleteData({
        api: `${api.DELETE_DESIGNATION}/${object.id}`,
      });
      console.log({ res });

      if (res) {
        setData((prevData) => prevData.filter((e) => e.id !== object.id));
      }

      setLoader(false);
    }
  };

  let onSubmitHandler = async (object) => {
    // console.log({ object });
    setLoader(true);

    if (updateMode) {
      let res = await UpdateData({ api: api.UPDATE_DESIGNATION, body: object });
      console.log({ res });
    } else {
      let res = await PostData({ api: api.CREATE_DESIGNATION, body: object });
      console.log({ res });
    }

    setLoader(false);
  };

  let onCancelHandler = () => {
    setUpdateMode(false);
  };

  let headers = [
    { title: "Id", dataIndex: "id" },
    { title: "Designation", dataIndex: "designation" },
    { title: "", type: "update" },
    { title: "", type: "delete" },
  ];

  return (
    <div>
      {loader && <Loader />}

      <Row gutter={[24, 24]}>
        <Col md={12}>
          <FormGenerator
            customLayout
            generatorData={formGenerator}
            formData={formData}
            formRules={formRules}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
          />
        </Col>

        <Col md={24} className="card pa-4">
          <DataTable
            rowKey="id"
            columns={headers}
            dataSource={data}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
          />
        </Col>
      </Row>
    </div>
  );
}

let formGenerator = [
  {
    type: "input",
    label: "Designation",
    placeholder: "Insert Designation",
    key: "designation",
  },
];

let formRules = {
  designation: {
    required: true,
  },
};
